import React from 'react';

const TextInput = ({ register, itemData, value, onValueChange }) => (
  <label className="contact-label" htmlFor={itemData.id}>
    <span style={{ display: 'none' }}>{itemData.label}</span>
    {onValueChange && value ? (
      <input
        id={itemData.id}
        {...register(itemData.id)}
        type="text"
        name={itemData.id}
        className="contact-input"
        placeholder={itemData.placeholder}
        required={itemData.required}
        value={value}
        onChange={(nValue) => (value ? onValueChange(nValue) : null)}
      />
    ) : (
      <input
        id={itemData.id}
        {...register(itemData.id)}
        type="text"
        name={itemData.id}
        className="contact-input"
        placeholder={itemData.placeholder}
        required={itemData.required}
      />
    )}
  </label>
);

export default TextInput;
