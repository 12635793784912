import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';

const position = [38.54849614331879, -7.911903367671341];

const ContactMap = ({ address }) => {
  if (typeof window !== 'undefined') {
    return (
      <MapContainer
        center={position}
        zoom={14}
        scrollWheelZoom={false}
        style={{ height: 500, width: '100%' }}
      >
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker position={position}>
          <Popup>
            <span
              dangerouslySetInnerHTML={{
                __html: address,
              }}
            />
          </Popup>
        </Marker>
      </MapContainer>
    );
  }

  return null;
};

export default ContactMap;
