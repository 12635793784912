import React from 'react';
import { Controller } from 'react-hook-form';
import BasePhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

const PhoneInput = ({ control, itemData }) => (
  <label className="contact-label" htmlFor={itemData.id}>
    <span style={{ display: 'none' }}>{itemData.label}</span>
    <Controller
      name={itemData.id}
      control={control}
      render={({ field }) => (
        <BasePhoneInput
          international
          {...field}
          className="contact-input"
          defaultCountry="PT"
          placeholder={itemData.placeholder}
          id={itemData.id}
          required={itemData.required}
        />
      )}
    />
  </label>
);

export default PhoneInput;
