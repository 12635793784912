import React from 'react';
import ContactForm from './ContactForm/ContactForm';
import ContactCardItem from './ContactCardItem/ContactCardItem';
import HorizontalBlueLines from '../../assets/horizontal_blue_lines.svg';

import './styles.scss';

const Contacts = ({ cardContacts, contactFormData }) => (
  <>
    <img
      src={HorizontalBlueLines}
      className="horizontal-lines"
      alt="Horizontal Blue Lines"
    />
    <div className="contacts-wrapper">
      <ContactForm contactFormData={contactFormData} />
      <div className="contact-card-wrapper">
        {cardContacts.map((elem, i) => (
          <ContactCardItem
            key={i.toString()}
            icon={elem.icon}
            title={elem.title}
            info={elem.info}
            href={elem.href}
          />
        ))}
      </div>
    </div>
  </>
);

export default Contacts;
