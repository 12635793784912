import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import PrimaryButton from '../../PrimaryButton/PrimaryButton';
import ContactNotification from '../ContactNotification/ContactNotification';
import PhoneInput from './PhoneInput/PhoneInput';
import TextInput from './TextInput/TextInput';
import TextareaInput from './TextareaInput/TextareaInput';
import EmailInput from './EmailInput/EmailInput';
import CheckboxInput from './CheckboxInput/CheckboxInput';
import './styles.scss';
import googleAnalitycsEvents from '../../../utils/googleAnalitycsEvents';

const getSubjectValue = (subjectTypes) => {
  if (typeof window !== 'undefined') {
    const urlParams = new URLSearchParams(window.location.search);
    const subjectParam = urlParams?.get('subject');

    if (subjectParam) {
      if (subjectParam === 'idea') {
        googleAnalitycsEvents('click', {
          event_category: 'contacts_i_have_an_idea',
        });
        return subjectTypes.idea;
      }
      if (subjectParam === 'scale') {
        googleAnalitycsEvents('click', {
          event_category: 'contacts_i_want_to_scale',
        });
        return subjectTypes.scale;
      }
      if (subjectParam === 'project') {
        googleAnalitycsEvents('click', {
          event_category: 'contacts_i_have_a_project',
        });
        return subjectTypes.project;
      }
    }
  }

  googleAnalitycsEvents('click', { event_category: 'contacts' });
  return '';
};

const ContactForm = ({ contactFormData }) => {
  const {
    title,
    successMessage,
    form,
    errorMessage,
    description,
    submitButton,
    subjectTypes,
  } = contactFormData;

  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [subjectValue, setSubjectValue] = useState(
    getSubjectValue(subjectTypes),
  );

  const { executeRecaptcha } = useGoogleReCaptcha();

  const addLead = (inputs, callback) => {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(inputs),
      redirect: 'follow',
    };

    fetch(
      `${process.env.GATSBY_PIPECODES_INTEGRATIONS_URL}pipedrive/lead`,
      requestOptions,
    )
      .then((response) => response.text())
      .then((result) => callback(result))
      .catch((error) => {
        console.log('error', error);
        setShowError(true);
      });
  };

  const encode = (data) =>
    Object.keys(data)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`,
      )
      .join('&');

  const sendContactForm = (e, inputs) => {
    fetch('/contacts', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': e.target.getAttribute('name'),
        ...inputs,
      }),
    })
      .then((res) => {
        if (!res.ok) {
          setShowError(true);
        } else {
          e.target.reset();
          setShowSuccess(true);
          googleAnalitycsEvents('click', { event_category: 'contact_sent' });
        }
      })
      .catch(() => {
        console.log('Form Function Failed');
        setShowError(true);
      });
  };

  useEffect(() => {
    const successTimeout = setTimeout(() => {
      setShowSuccess(false);
    }, 5000);

    return () => {
      clearTimeout(successTimeout);
    };
  }, [showSuccess]);

  useEffect(() => {
    const errorTimeout = setTimeout(() => {
      setShowError(false);
    }, 3000);

    return () => {
      clearTimeout(errorTimeout);
    };
  }, [showError]);

  const onSubmit = (data, e) => {
    executeRecaptcha('action')
      .then(() => {
        addLead(data, () => {
          sendContactForm(e, data);
        });
      })
      .catch((e) => {
        console.log('Recaptcha Failed');
        console.log(e);
        setShowError(true);
      });
  };

  const { register, control, handleSubmit } = useForm();

  return (
    <form
      method="POST"
      className="contact-form"
      data-netlify="true"
      name="contact"
      netlify-honeypot="bot-field"
      onSubmit={handleSubmit(onSubmit)}
    >
      <span className="title">{title}</span>
      <span className="description">{description}</span>
      <input type="hidden" name="form-name" value="contact" />
      <input type="hidden" name="bot-field" />
      <TextInput
        register={register}
        itemData={form.find((field) => field.id === 'name')}
      />
      <div className="double-row">
        <PhoneInput
          control={control}
          register={register}
          itemData={form.find((field) => field.id === 'phone')}
        />
        <EmailInput
          register={register}
          itemData={form.find((field) => field.id === 'email')}
        />
      </div>

      <TextInput
        register={register}
        itemData={form.find((field) => field.id === 'subject')}
        value={subjectValue}
        onValueChange={(nValue) => setSubjectValue(nValue?.value)}
      />

      <TextareaInput
        register={register}
        itemData={form.find((field) => field.id === 'message')}
      />
      <CheckboxInput
        register={register}
        itemData={form.find((field) => field.id === 'policies')}
      />
      <CheckboxInput
        register={register}
        itemData={form.find((field) => field.id === 'newsletter')}
      />
      {showError && <ContactNotification error text={errorMessage} />}
      {showSuccess && <ContactNotification text={successMessage} />}
      <PrimaryButton submit size="xs" text={submitButton} />
    </form>
  );
};

export default ContactForm;
