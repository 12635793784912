import React from 'react';
import './styles.scss';

const ContactNotification = ({ text, error = false }) => (
  <div className={`notification slide-in-elliptic-top-fwd ${error && 'error'}`}>
    {!error && <span className="check">&#10003;</span>}
    {error && <span className="check">X</span>}
    <span className="text">{text}</span>
  </div>
);

export default ContactNotification;
