import React from 'react';

const EmailInput = ({ register, itemData }) => (
  <label className="contact-label" htmlFor={itemData.id}>
    <span style={{ display: 'none' }}>{itemData.label}</span>
    <input
      id={itemData.id}
      {...register(itemData.id)}
      type="text"
      name={itemData.id}
      className="contact-input"
      placeholder={itemData.placeholder}
      required={itemData.required}
    />
  </label>
);

export default EmailInput;
