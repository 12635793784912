import React, { useMemo } from 'react';
import { graphql } from 'gatsby';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import Layout from '../layouts/layout';
import SectionTitle from '../components/SectionTitle/SectionTitle';
import '../styles';
import SEO from '../components/SEO';
import Section from '../components/Section/Section';
import ContentWrapper from '../components/ContentWrapper/ContentWrapper';
import Contacts from '../components/Contacts/Contacts';
import ContactMap from '../components/Contacts/ContactMap/ContactMap';
import Breadcrumbs from '../components/Breadcrumbs/Breadcrumbs';
import CookieBar from '../components/CookieBar/CookieBar';

const Page = ({ pageContext, data }) => {
  const { title, cardContacts, contactForm, contactMap, seo } =
    data.content.frontmatter;

  const crumbs = useMemo(
    () => [
      { title: 'Home', href: '/' },
      { title, href: null },
    ],
    [title],
  );

  const url = typeof window !== 'undefined' ? window.location.href : '';
  return (
    <GoogleReCaptchaProvider
      useRecaptchaNet
      reCaptchaKey={process.env.GATSBY_RECAPTCHA_KEY}
      scriptProps={{ async: true, defer: true, appendTo: 'body' }}
    >
      <Layout
        showHeaderAfterScroll={false}
        header={data.header}
        footer={data.footer}
        slug={pageContext.slug}
      >
        <SEO seo={seo} url={url} />
        <Section>
          <Breadcrumbs crumbs={crumbs} />
          <SectionTitle title={title} />
          <ContentWrapper>
            <Contacts
              cardContacts={cardContacts}
              contactFormData={contactForm}
            />
          </ContentWrapper>
          <ContactMap address={contactMap?.address} />
        </Section>
        <CookieBar data={data.cookies_bar} />
      </Layout>
    </GoogleReCaptchaProvider>
  );
};
export default Page;

export const query = graphql`
  query ContactsQuery($id: String!, $language: String!) {
    content: markdownRemark(
      id: { eq: $id }
      frontmatter: { language: { eq: $language } }
    ) {
      frontmatter {
        seo {
          title
          description
          facebookAppID
          image
          keywords
          twitterUsername
        }
        title
        linkUrl
        language
        cardContacts {
          icon
          title
          info
          href
        }
        contactForm {
          title
          description
          subjectTypes {
            idea
            scale
            project
          }
          errorMessage
          successMessage
          submitButton
          form {
            type
            id
            required
            placeholder
            rows
            description
          }
        }
        contactMap {
          address
        }
      }
    }
    header: markdownRemark(
      frontmatter: {
        templateKey: { eq: "navbar" }
        language: { eq: $language }
      }
    ) {
      frontmatter {
        language
        logo {
          image
          imageAlt
        }
        logoFixed {
          image
          imageAlt
        }

        menuItems {
          label
          linkUrl
          openNewTab
        }
      }
    }

    footer: markdownRemark(
      frontmatter: {
        templateKey: { eq: "footer" }
        language: { eq: $language }
      }
    ) {
      frontmatter {
        projectLabel
        termsLabel
        privacyLabel
        language
        logo {
          image
          imageAlt
        }
        description

        followus {
          socialmedia {
            logo
            link
          }
          title
        }

        getInTouch {
          title
          body
        }

        useful_links {
          title
          menu {
            title
            link
            openNewTab
          }
        }

        copyright
      }
    }

    cookies_bar: markdownRemark(
      frontmatter: {
        templateKey: { eq: "cookies_bar" }
        language: { eq: $language }
      }
    ) {
      frontmatter {
        language
        title
        message
        acceptButton
        closeButton
      }
    }
  }
`;
