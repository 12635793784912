import React from 'react';
import './styles.scss';

const Breadcrumbs = ({ crumbs }) => (
  <div className="breadcrumbs">
    <div className="wrapper">
      {crumbs.map((crumb, i) => (
        <div key={i.toString()}>
          <a href={crumb.href}>{crumb.title}</a>
          {i + 1 < crumbs.length && <span>&gt;</span>}
        </div>
      ))}
    </div>
  </div>
);

export default Breadcrumbs;
