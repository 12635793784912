import React from 'react';
import googleAnalitycsEvents from '../../../utils/googleAnalitycsEvents';
import './styles.scss';

const ContactCardItem = (props) => {
  const { icon, title, info, href } = props;

  return (
    <div className="contact-card-item">
      <div className="title-wrapper">
        <img className="icon" alt="icon" src={icon} />
        <span className="title">{title}</span>
      </div>

      <a
        className="info"
        href={href}
        onClick={() => {
          googleAnalitycsEvents('click', {
            event_category: `contact_card_clicked_${title.toLocaleLowerCase()}`,
          });
        }}
      >
        {info}
      </a>
    </div>
  );
};

export default ContactCardItem;
