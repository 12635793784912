import React from 'react';

const TextareaInput = ({ register, itemData }) => (
  <label className="contact-label" htmlFor={itemData.id}>
    <span style={{ display: 'none' }}>{itemData.label}</span>
    <textarea
      id={itemData.id}
      {...register(itemData.id)}
      name={itemData.id}
      rows={itemData.rows}
      className="contact-input contact-input-multiline"
      placeholder={itemData.placeholder}
      required={itemData.required}
    />
  </label>
);

export default TextareaInput;
