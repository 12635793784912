import React from 'react';

const CheckboxInput = ({ register, itemData }) => (
  <div className="checkbox-wrapper">
    <label htmlFor={itemData.id}>
      <input
        {...register(itemData.id)}
        required={itemData.required}
        type="checkbox"
        id={itemData.id}
        name={itemData.id}
      />
      <span dangerouslySetInnerHTML={{ __html: itemData.description }} />
    </label>
  </div>
);

export default CheckboxInput;
